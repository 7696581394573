import { Divider, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { map } from 'ramda';
import { isArray } from 'ramda-adjunct';
import React, { Fragment } from 'react';

import { Footer } from '../components/Footer';
import Header from '../components/Header';
import { Nav } from '../components/Nav';
import { TopLayout } from '../components/TopLayout';

interface Row {
  city: string;
  date: string;
  event?: string;
  img?: string | string[];
  name: string;
  state: string;
  attending?: string[];
}

const rows: Row[] = [
  {
    city: 'Howell',
    date: '2003 03 27',
    name: 'Howell High School',
    state: 'MI',
  },
  {
    city: 'Warren',
    date: '2007 03 09',
    name: 'Cleme’s Pour House',
    state: 'MI',
  },
  {
    city: 'Howell',
    date: '2007 03 10',
    event: 'OGREFEST 2007',
    img: '/images/posters/Ogrefest-2007-Color.jpg',
    name: 'The Red Apple Bar',
    state: 'MI',
  },
  {
    city: 'Adrian',
    date: '2007 10 10',
    name: 'LA Cafe',
    state: 'MI',
  },
  {
    attending: ['Battlecross', 'Afterain', 'All Else Fails'],
    city: 'Westland',
    date: '2007 10 28',
    name: 'Token Lounge',
    state: 'MI',
  },
  {
    attending: ['Genocya', 'Intrusion', 'Battlecross'],
    city: 'Lansing',
    date: '2007 11 17',
    img: [
      '/images/posters/The-Last-Thanksgiving.jpg',
      '/images/posters/last-thanksgiving-2007.jpg',
    ],
    name: 'Oade’s Hidden Camel',
    state: 'MI',
  },
  {
    city: 'Lansing',
    date: '2008 02 02',
    event: 'OGREFEST 2008',
    img: '/images/posters/Ogrefest-2008.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: ['Blood Harvest'],
    city: 'Toledo',
    date: '2008 02 16',
    name: 'Ottawa Tavern',
    state: 'OH',
  },
  {
    attending: ['Battlecross', 'Defend the Malign'],
    city: 'Detroit',
    date: '2008 03 14',
    img: '/images/posters/Corktown-08.jpg',
    name: 'Corktown Tavern',
    state: 'MI',
  },
  {
    city: 'Coleman',
    date: '2008 03 29',
    name: 'Nebula Underground',
    state: 'MI',
  },
  {
    attending: ['BlöödHag', 'Cavavalcade'],
    city: 'Lansing',
    date: '2008 03 30',
    img: '/images/posters/Bloodhag-Craig.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: ['Theocracide', 'Stained Glass Torture'],
    city: 'Detroit',
    date: '2008 05 10',
    img: '/images/posters/irock-08.jpg',
    name: 'I-Rock Nightclub',
    state: 'MI',
  },
  {
    attending: ['Ultrathrash', 'Demoniarch', 'Incipient Madness'],
    city: 'Grand Rapids',
    date: '2008 05 24',
    img: '/images/posters/teasers-09.gif',
    name: 'Teazers',
    state: 'MI',
  },
  {
    city: 'Lansing',
    date: '2008 06 20',
    name: 'Basement 414',
    state: 'MI',
  },
  {
    attending: ['Genocya'],
    city: 'Fort Wayne',
    date: '2008 06 20',
    name: 'Berlin Music Pub',
    state: 'IN',
  },
  {
    city: 'Santon',
    date: '2008 06 27',
    name: 'Moreland\'s Motocross',
    state: 'MI',
  },
  {
    attending: ['Through the Mist'],
    city: 'Ludington',
    date: '2008 07 05',
    event: 'Mason Metal Fest III',
    img: '/images/posters/Fritzs-Pour-House-2008.jpg',
    name: 'Fritz’s Pour House',
    state: 'MI',
  },
  {
    attending: ['Sauron', 'Dagon', 'Ultrathrash'],
    city: 'Grand Rapids',
    date: '2008 10 11',
    img: '/images/posters/The-Lords-of-the-Strings.gif',
    name: 'Teazers',
    state: 'MI',
  },
  {
    attending: ['Nactmystium', 'Wolves in the Throne Room'],
    city: 'Lansing',
    date: '2008 10 24',
    img: '/images/posters/Nachtmystium.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: [' Dark Psychosis', 'Writhing', 'Dioxin'],
    city: 'Redford',
    date: '2008 11 01',
    img: '/images/posters/The-All-Saints-Massacre.jpg',
    name: 'Double OO Pub',
    state: 'MI',
  },
  {
    attending: ['Today is the Day', 'Weedeater', 'Hordes'],
    city: 'Lansing',
    date: '2008 11 14',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: ['Battlefields', 'Irepress', 'Beast in the Field'],
    city: 'Lansing',
    date: '2009 03 07',
    img: '/images/posters/battlefields-march7th-09.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    city: 'Lansing',
    date: '2009 04 25',
    event: 'OGREFEST 2009',
    img: '/images/posters/Ogrefest-2009.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: ['Dagon, Year200X'],
    city: 'East Lansing',
    date: '2009 05 01',
    img: '/images/posters/replay-09.jpg',
    name: 'Replay Ent. Exchange',
    state: 'MI',
  },
  {
    attending: ['Anal Cunt', 'Red White & Booze'],
    city: 'Lansing',
    date: '2009 05 07',
    img: '/images/posters/AnalCunt.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: ['Harbinger', 'Vindicator', 'Trazom'],
    city: 'Lansing',
    date: '2009 05 10',
    img: '/images/posters/vindicator09.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: ['Blackholicus', 'Harbinger'],
    city: 'Lansing',
    date: '2009 05 17',
    img: '/images/posters/blackholicus-09.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: ['Cavalcade', 'Wastelander'],
    city: 'Hamtramck',
    date: '2009 05 22',
    name: 'Paycheck’s Lounge',
    state: 'MI',
  },
  {
    attending: ['Vindicator', 'Trazom'],
    city: 'Cleveland',
    date: '2009 05 25',
    event: 'The Peterman Re-Reality Tour',
    img: '/images/posters/cleveland-09.jpg',
    name: 'Now That’s Class',
    state: 'OH',
  },
  {
    attending: ['Plastered Bastards', 'Satan’s Sidekick'],
    city: 'Pittsburgh',
    date: '2009 05 26',
    event: 'The Peterman Re-Reality Tour',
    name: 'Howler’s',
    state: 'PA',
  },
  {
    attending: ['Siege A.D.'],
    city: 'Buffalo',
    date: '2009 05 27',
    event: 'The Peterman Re-Reality Tour',
    name: 'Broadway Joe’s',
    state: 'Buffalo',
  },
  {
    attending: ['Razormaze', 'Candy Striper Death'],
    city: 'Worcester',
    date: '2009 05 28',
    event: 'The Peterman Re-Reality Tour',
    img: '/images/posters/Metal-Thursday-2009.jpg',
    name: 'Ralph’s Rock Diner',
    state: 'MA',
  },
  {
    attending: ['Reign of Pestilence'],
    city: 'New Bedford',
    date: '2009 05 29',
    event: 'The Peterman Re-Reality Tour',
    name: 'The Transient Authority',
    state: 'MA',
  },
  {
    attending: ['Waking the Cadaver'],
    city: 'Trenton',
    date: '2009 05 30',
    event: 'The Peterman Re-Reality Tour',
    name: 'Championship Bar',
    state: 'NJ',
  },
  {
    attending: ['Kructation', 'A Great Day to Die'],
    city: 'Brooklyn',
    date: '2009 05 31',
    event: 'The Peterman Re-Reality Tour',
    img: '/images/posters/brooklyn-09.jpg',
    name: 'The Charleston',
    state: 'NY',
  },
  {
    attending: ['Crimson Orchid'],
    city: 'Baltimore',
    date: '2009 06 01',
    event: 'The Peterman Re-Reality Tour',
    name: 'Charm City Art Space',
    state: 'MA',
  },
  {
    attending: ['The Leviathan’s Mandible'],
    city: 'Richmond',
    date: '2009 06 02',
    event: 'The Peterman Re-Reality Tour',
    name: 'Nara Sushi',
    state: 'VA',
  },
  {
    attending: ['Bloodsoaked'],
    city: 'Raleigh',
    date: '2009 06 03',
    event: 'The Peterman Re-Reality Tour',
    name: 'Volume 11 Tavern',
    state: 'NC',
  },
  {
    attending: ['Ex Animis Mortuum', 'Arbalest'],
    city: 'Tampa',
    date: '2009 06 05',
    event: 'The Peterman Re-Reality Tour',
    img: '/images/posters/The-Brass-Mug-2009.gif',
    name: 'The Brass Mug',
    state: 'FL',
  },
  {
    attending: ['ZCFOS'],
    city: 'Orlando',
    date: '2009 06 06',
    event: 'The Peterman Re-Reality Tour',
    name: 'The Dungeon',
    state: 'FL',
  },
  {
    city: 'Gainesville',
    date: '2009 06 07',
    event: 'The Peterman Re-Reality Tour',
    name: 'Gainesville Skatepark',
    state: 'FL',
  },
  {
    attending: ['Marais Noir', 'Savius'],
    city: 'Westwego',
    date: '2009 06 09',
    event: 'The Peterman Re-Reality Tour',
    name: 'The M.V.C',
    state: 'LA',
  },
  {
    attending: ['Blackholicus'],
    city: 'Austin',
    date: '2009 06 10',
    event: 'The Peterman Re-Reality Tour',
    name: 'Headhunter’s',
    state: 'TX',
  },
  {
    city: 'Chicago',
    date: '2009 06 17',
    event: 'The Peterman Re-Reality Tour',
    img: '/images/posters/chicago-09.jpg',
    name: 'Metal Shaker',
    state: 'IL',
  },
  {
    attending: ['Cavalcade'],
    city: 'Lansing',
    date: '2009 06 20',
    event: 'The Peterman Re-Reality Tour',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: ['Cavlacade', 'Intrusion'],
    city: 'Gladstone',
    date: '2009 08 05',
    event: 'Bad Idea Tour',
    name: 'V.F.W Hall',
    state: 'MI',
  },
  {
    attending: ['Cavalcade'],
    city: 'Stevens Point',
    date: '2009 08 07',
    event: 'Bad Idea Tour',
    name: 'Beast House',
    state: 'WI',
  },
  {
    attending: ['Cavalcade', 'Myiasis'],
    city: 'Minneapolis',
    date: '2009 08 08',
    event: 'Bad Idea Tour',
    name: 'Club Underground',
    state: 'MN',
  },
  {
    attending: ['Cavalcade', 'The Bartender'],
    city: 'Chicago',
    date: '2009 08 09',
    event: 'Bad Idea Tour',
    name: 'Metal Shaker',
    state: 'IL',
  },
  {
    attending: ['Atlas Moth', 'Dark Castle', 'Failed', 'Cavalcade'],
    city: 'Lansing',
    date: '2009 11 21',
    img: '/images/posters/atlasmoth.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: ['Battlecross', 'Powerslave'],
    city: 'Westland',
    date: '2009 11 25',
    img: '/images/posters/token-09.jpg',
    name: 'Token Lounge',
    state: 'MI',
  },
  {
    attending: ['Misery Index', 'Magrudergrind', 'Dagon'],
    city: 'Lansing',
    date: '2010 02 26',
    img: '/images/posters/Misery-Index.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: ['Deceased', 'Superchrist', 'Genocya'],
    city: 'Lansing',
    date: '2010 04 23',
    img: '/images/posters/deceased-10.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    city: 'Lansing',
    date: '2010 05 01',
    event: 'OGREFEST 2010',
    img: '/images/posters/Ogrefest-2010.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: ['Genocya', 'Shadow Self', 'Writhing'],
    city: 'Allen Park',
    date: '2010 06 12',
    event: 'Metal For MS',
    img: '/images/posters/allenpark-10.jpg',
    name: 'Simon’s',
    state: 'MI',
  },
  {
    city: 'Lansing',
    date: '2011 04 16',
    event: 'OGREFEST 2011',
    img: '/images/posters/Ogrefest-2011.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: ['Genocya', 'Dagon', 'The Devastator', 'Halstatt'],
    city: 'Lansing',
    date: '2012 01 13',
    img: '/images/posters/Friday-the-13th.png',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    city: 'Lansing',
    date: '2012 04 14',
    event: 'OGREFEST 2012',
    img: '/images/posters/Ogrefest-2012.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    attending: ['Witch Mountain', 'Lord Dying', 'Borrowed Time'],
    city: 'Lansing',
    date: '2012 06 15',
    img: '/images/posters/Witch-Mountain.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    city: 'Lansing',
    date: '2013 05 18',
    event: 'OGREFEST 2013',
    img: '/images/posters/Ogrefest-2013.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
  {
    city: 'Lansing',
    date: '2014 05 10',
    event: 'OGREFEST 2014',
    img: '/images/posters/Ogrefest2014.jpg',
    name: 'Mac’s Bar',
    state: 'MI',
  },
];

const generateRow = ({
  city,
  date,
  event,
  img,
  name,
  attending,
  state,
}: Row) => (
  <TableRow key={`${date}${city}${state}${name}`}>
    <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>{date}</TableCell>
    <TableCell align="left">
      {name}
      {img && (
        map(img => (
          <div>
            <img
              src={img}
              style={{ margin: '1em 0 0 0', maxHeight: '300px', maxWidth: '300px' }}
            />
          </div>
        ), isArray(img) ? img : [img])
      )}
    </TableCell>
    <TableCell>{city}, {state}</TableCell>
    <TableCell>{event}</TableCell>
    <TableCell>{attending && attending.join(', ')}</TableCell>
  </TableRow>
);

const PastShows = () => (
  <Container maxWidth="md">
    <Typography style={{ marginBottom: '0.5em', marginTop: '1em' }} variant="h4">Past Shows</Typography>
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Venue</TableCell>
            <TableCell>City, State</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>Attending</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(generateRow, rows)}
        </TableBody>
      </Table>
    </Paper>
  </Container>
);

const bio = 'Michigan’s Satyrasis is an enigmatic heavy metal triumvirate with thrash metal roots and ever expanding progressive tendencies. Their 2nd full-length, “…Of The Dead,” is an opus built upon a conceptual descent through the extremity of the human condition. Each song is a door beyond which the consumptive ear may listen in on tales of ambition and addiction, money and obsession, murder and war, naked in their tones of rage, humor and sadness. Crack the door and be pinned down in a hail of riff-fire, find yourself inexplicably submerged into a sea of atmospheric time signature bending, only to resurface into a menacing maelstrom of contrapuntal chaos. “…Of The Dead” was released on Seventh Door Records January 27th, 2015.';

function App() {
  return (
    <React.Fragment>
      <Nav />
      <Header />
      <Container maxWidth="md">
        <Typography>{bio}</Typography>

        <Divider style={{ margin: '2em 0 1em 0' }} variant="fullWidth" />

      </Container>

      <PastShows />

      <Footer />
    </React.Fragment>
  );
}

export default function Default() {
  return (
    <TopLayout>
      <App />
    </TopLayout>
  );
}
